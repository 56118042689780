import Typography from "@mui/material/Typography";

const Lineup = () => {
  return (
    <>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        BANDS
      </Typography>

      <Typography variant="body2">
        <a
          href={"https://ancientemblem.bandcamp.com/"}
          target="_blank"
          rel="noreferrer"
        >
          Ancient Emblem{" "}
        </a>{" "}
        (DE)
      </Typography>
      <Typography variant="body2">
        {" "}
        <a
          href={"https://www.facebook.com/share/193piK4z9r/?mibextid=LQQJ4d"}
          target="_blank"
          rel="noreferrer"
        >
          Counterblast
        </a>{" "}
        (SE)
      </Typography>
      <Typography variant="body2">
        {" "}
        <a
          href={"https://www.facebook.com/KovaaRasvaa"}
          target="_blank"
          rel="noreferrer"
        >
          Kovaa Rasvaa
        </a>{" "}
        (FI)
      </Typography>
      <Typography variant="body2">
        {" "}
        <a
          href={"https://lautsturmer.bandcamp.com"}
          target="_blank"
          rel="noreferrer"
        >
          Lautstürmer
        </a>{" "}
        (SE)
      </Typography>
      <Typography variant="body2">
        <a
          href={"https://lifescars.bandcamp.com/"}
          target="_blank"
          rel="noreferrer"
        >
          Life Scars
        </a>{" "}
        (PL)
      </Typography>
      <Typography variant="body2">
        {" "}
        <a
          href={"https://motorbreath.bandcamp.com/track/enter-the-bar"}
          target="_blank"
          rel="noreferrer"
        >
          Motorbreath
        </a>{" "}
        (SE)
      </Typography>
      <Typography variant="body2">Nödvärn (SE)</Typography>
      <Typography variant="body2">
        {" "}
        <a
          href={"https://ruinnationrecords.bandcamp.com/album/saorsa"}
          target="_blank"
          rel="noreferrer"
        >
          Oi Polloi
        </a>{" "}
        (SCO)
      </Typography>
      <Typography variant="body2">Retch (SE)</Typography>
      <Typography variant="body2">
        {" "}
        <a
          href={"https://skrot.bandcamp.com/"}
          target="_blank"
          rel="noreferrer"
        >
          Skrot
        </a>{" "}
        (SE)
      </Typography>
      <Typography variant="body2">
        {" "}
        <a
          href={"https://youtube.com/playlist?list=OLAK5uy_lBOpvISr-T9aTUZcG5odUO1SbtT1ryd20&si=rZ3GiUpejov8RXjX"}
          target="_blank"
          rel="noreferrer"
        >
          Stormen Gorm
        </a>{" "}
        (SE)
      </Typography>
      <Typography variant="body2">
        <a
          href={"https://zyfilis.bandcamp.com/"}
          target="_blank"
          rel="noreferrer"
        >
          Zyfilis
        </a>{" "}
        (SE/DK)
      </Typography>

      {/*<Typography*/}
      {/*  variant="h6"*/}
      {/*  component="div"*/}
      {/*  style={{ marginTop: "15px", marginBottom: "10px" }}*/}
      {/*>*/}
      {/*  Check out this <a*/}
      {/*    href={*/}
      {/*      "https://www.youtube.com/playlist?list=PL6dpd1j-ROECd5WELeCHioSiDHLqQwNGg"*/}
      {/*    }*/}
      {/*    target="_blank"*/}
      {/*    rel="noreferrer"*/}
      {/*  >YouTube playlist</a> to hear some more music from the bands!*/}
      {/*</Typography>*/}
      {/*<Typography*/}
      {/*  variant="h5"*/}
      {/*  component="div"*/}
      {/*  style={{ marginTop: "25px", marginBottom: "10px" }}*/}
      {/*>*/}
      {/*  DJs*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">The Ratpack Crew - Fiffifreedom & Kloakerkan</Typography>*/}
      {/*<Typography variant="body2">Moahawk & Siv Saliv</Typography>*/}
      {/*<Typography*/}
      {/*  variant="h5"*/}
      {/*  component="div"*/}
      {/*  style={{ marginTop: "25px", marginBottom: "10px" }}*/}
      {/*>*/}
      {/*  RUNNING ORDER*/}
      {/*</Typography>*/}
      {/*<Typography variant="h6">Friday*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">18.00: Warför*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">19.00: Böset*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">20.00: Barräkas*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">21.00: Varoitus*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">23.00: he Lost his iro in an apocalipstix world*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">24:00: Sju svåra år*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">01:00: Tolshock*/}

      {/*</Typography>*/}
      {/*<Typography variant="h6">Saturday*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">16.00: Terror 83*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">17.00: Heavy bleeding*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">18:00: Vivisectio*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">19:00: Agrimonia*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">20.00: Kovaa Rasvaa*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">21.00: Asocial*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">22.00: Indre krig*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">23.00: Avskum*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">24.00: Protestera*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">01.00: Dropdead*/}
      {/*</Typography>*/}
    </>
  );
};

export default Lineup;
